import React, {useRef} from 'react';
import './style.scss';

import { FiClock, FiEdit, FiUser, FiMail, FiPhone, FiMapPin, FiHome } from 'react-icons/fi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { Form } from '@unform/web';
import fetch from 'isomorphic-fetch';

import Input from './../components/Form/input';
import Textarea from './../components/Form/textarea';
import ReactSelect from './../components/Form/select';

import * as Yup from 'yup';
import swal from 'sweetalert';

function Contato() {
  let loading = false;
  const formRef = useRef(null);

  async function handleSubmit(data, {reset}) {
  
    try {      
      const schema =  Yup.object().shape({
        nome: Yup.string().required('O nome é obrigatório'),
        email:Yup.string().email("E-mail inválido").required("O e-mail é obrigatório"),
        mensagem:Yup.string().required('A mensagem é obrigatória'),
        telefone:Yup.string().required('O telefone é obrigatório'),
        rua:Yup.string().required('O endereço é obrigatório'),
        bairro:Yup.string().required('O bairro é obrigatório')
      });

      await schema.validate(data,{
        abortEarly: false,
      });

      loading = true;

      let payload = {
        "origin": "www.elitetelecomsp.com.br",
        "name": data.nome,
        "email": data.email,
        "message": data.mensagem,
        "parameters": {
            "celular": data.telefone,
            "endereco": data.rua,
            "bairro": data.bairro
        },
        "company": {
            "name": "Elite Telecom SP",
            "email": "contato@elitetelecomsp.com.br"
        }
      }
  
      return fetch('https://o3fphwgix5.execute-api.us-east-1.amazonaws.com/fox-send-mail', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            formRef.current.setErrors({});
            reset();
            swal("Enviado!", "Em breve entraremos em contato!", "success");
            loading = false;
            return res;
        }).catch(err => {
          loading = false;
          return err;
        });

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};

        err.inner.forEach(error => {
          errorMessages[error.path] = error.message;
        })

        formRef.current.setErrors(errorMessages);
        console.log(errorMessages);
      }
    }
  }
    return (
      <div className="contato" id="contato">
        <section className="ls formin">
            <div className="container">
              <div className="row m-auto">
                <div className="col-12 col-lg-8 p-0">
                  <h5 className="text-center text-md-left special-heading">
                    entre em contato e
                    <br />
                    <span className="text-uppercase">assine agora</span>
                  </h5>
                  <div className="divider-50 d-none d-xl-block" />

                  <Form ref={formRef}  className="contact-form pr-lg-5 c-mb-10 c-mb-md-20 mt-4" onSubmit={handleSubmit}>
                  <div className="row m-auto">
                    <div className="col-sm-12">
                      <div className="form-group has-placeholder">
                        <FiUser size={16} className="redLight"/>
                        <Input name="nome" placeholder="Nome completo" className="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div className="row m-auto">
                    <div className="col-sm-6">
                      <div className="form-group has-placeholder">
                        <FiPhone size={16} className="redLight"/>
                        <Input type="tel" name="telefone" placeholder="Telefone" className="form-control"/>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group has-placeholder">
                        <FiMail size={16} className="redLight"/>
                        <Input type="text" name="email" placeholder="E-mail" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row m-auto">
                    <div className="col-sm-7">
                      <div className="form-group has-placeholder">
                        <FiHome size={16} className="redLight"/>
                        <Input type="text"  name="rua" placeholder="Endereço" className="form-control"/>
                      </div>
                    </div>
                    <div className="col-sm-5">
                      <div className="form-group has-placeholder select-formi">
                        <FiMapPin size={16} className="redLight"/>
                        <Input type="text"  name="bairro" placeholder="Bairro" className="form-control"/>
                      </div>
                    </div>
                  </div>
                  <div className="row m-auto">
                    <div className="col-sm-12">
                      <div className="form-group has-placeholder">
                        <FiEdit size={16} className="redLight"/>
                        <Textarea aria-required="true" rows={6} cols={45} name="mensagem" className="form-control" placeholder="Sua Mensagem" />
                      </div>
                    </div>
                  </div>
                  <div className="row m-auto">
                    <div className="col-sm-12 col-sm-12 mt-3 mt-lg-0">
                      <button type="submit" className="btn btn-maincolor" disabled={loading ? true : undefined}><span>{loading ? "Enviando..." : "Enviar"}</span></button>
                    </div>
                  </div>
                  </Form>
                </div>
                <div className="col-lg-4 mt-lg-5 pt-5 pr-0 pl-lg-2">
                  <h4 className="d-inline-block  mt-4">Atendimento <FontAwesomeIcon icon={faWhatsapp} className="whats"/></h4> 
                  <div className="row c-gutter-30">
                    <div className="col-auto pr-2">
                      <span className="color-darkgrey fw-500">Ivan:</span>
                    </div>
                    <div className="col-auto pl-0"><a href="https://api.whatsapp.com/send?phone=5511955963327&&text=Olá vim através do site www.elitetelecomsp.com.br, gostaria de saber mais sobre os serviços." target="_blank"rel="noopener noreferrer">11 9 5596-3327</a></div>
                  </div>
                  <h4 className="d-inline-block">Horários <FiClock className="redLight" size={34}/></h4> 
                  <div className="row c-gutter-30">
                    <div className="col-auto pr-2">
                      <span className="color-darkgrey fw-500">Segunda à sexta:</span>
                    </div>
                    <div className="col-auto pl-0">08:00 - 18:00</div>
                  </div>
                  <div className="row c-gutter-30">
                    <div className="col-auto pr-2">
                      <span className="color-darkgrey fw-500">Sábado</span>
                    </div>
                    <div className="col-auto pl-0">09:00 - 13:00</div>
                  </div>
                  <div className="row c-gutter-30">
                    <div className="col-auto pr-2">
                      <span className="color-darkgrey fw-500">Domingos e Feriados:</span>
                    </div>
                    <div className="col-auto p-0">Fechado</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </div>
    )
  }

export default Contato;