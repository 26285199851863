import React from 'react';
import { Accordion, Card, Button} from "react-bootstrap";
import { FiChevronDown } from 'react-icons/fi';
import Slider from "react-slick";

import '././style.scss';

const televisao = require('./../assets/televisao.png');
const velocidadeUm = require('./../assets/velocimetro-1.png');
const velocidadeDois = require('./../assets/velocimetro-2.png');
const velocidadeTres = require('./../assets/velocimetro-3.png');

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    />
  );
}

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

class Home extends React.Component{
    render() {
        return(
          // PLANOS
            <div>
            <section className="s-py-80 s-py-lg-120 s-py-xl-160 planos" id="planos">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2" id="price">
                    <h5 className="special-heading text-center mt-3">Escolha um</h5>
                    <h1 className="text-center">Plano</h1>
                    <p className="text-center big">
                    Oferecemos os melhores planos conforme sua necessidade. Assine já!
                    </p>
                  </div>
                </div>
                <div className="divider-45 d-none d-lg-block" />
                <div className="row">
                <Slider {...settings}>
                  <div className="col-lg-12 col-12">
                    <div className="pricing-plan hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor">
                        <h3>20 megas</h3>
                      </div>
                      <div className="plan-desc">
                        <div className="plan-content">
                          <h4 className="color-main2">20</h4>
                          <p className="small-text text-left">
                          <FiChevronDown size={16} className="redLight"/>mb/s
                          </p>
                          {/* <h4 className="color-main">05</h4>
                          <p className="small-text text-left">
                          <FiChevronUp size={16} className="redDark"/> mb/s
                          </p> */}
                        </div>
                        <div className="price-icon">
                          <img src={velocidadeUm} alt="Velocidade" />
                        </div>
                      </div>
                      <div className="plan-features">
                        <ul className="list-bordered">
                          <li>Atendimento 24 horas</li>
                          <li>Sem consulta ao SPC/Serasa</li>
                          <li>Wi-fi Grátis</li>
                        </ul>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main2">49 <sup>,90</sup></span>
                        <span className="plan-decimals small-text">/Mês</span>
                        <small><i>*Taxa de Adesão 2x R$ 80 no cartão</i></small>
                      </div>
                      <div className="plan-button">
                        <a href="#contato" className="btn btn-maincolor">
                          <span>Assine Agora</span>
                        </a>
                      </div>
                    </div>
                    <div className="divider-45 d-block d-lg-none" />
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="pricing-plan hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor">
                        <h3>40 megas</h3>
                      </div>
                      <div className="plan-desc">
                        <div className="plan-content">
                          <h4 className="color-main2">40</h4>
                          <p className="small-text">
                          <FiChevronDown size={16} className="redLight"/> mb/s
                          </p>
                          {/* <h4 className="color-main">10</h4>
                          <p className="small-text">
                          <FiChevronUp size={16} className="redDark"/> mb/s
                          </p> */}
                        </div>
                        <div className="price-icon">
                          <img src={velocidadeUm} alt="Velocidade" />
                        </div>
                      </div>
                      <div className="plan-features">
                        <ul className="list-bordered">
                          <li>Atendimento 24 horas</li>
                          <li>Sem consulta ao SPC/Serasa</li>
                          <li>Wi-fi Grátis</li>
                        </ul>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">RS</span>
                        <span className="plan-price color-main2">69 <sup>,90</sup></span>
                        <span className="plan-decimals small-text">/Mês</span>
                        <br/>
                        <small><i>*Taxa de Adesão 2x R$ 80 no cartão</i></small>
                      </div>
                      <div className="plan-button">
                        <a href="#contato" className="btn btn-maincolor">
                          <span>Assine Agora</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="pricing-plan hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor">
                        <h3>60 megas</h3>
                      </div>
                      <div className="plan-desc">
                        <div className="plan-content">
                          <h4 className="color-main2">60</h4>
                          <p className="small-text">
                            <FiChevronDown size={16} className="redLight"/> mb/s
                          </p>
                          {/* <h4 className="color-main">20</h4>
                          <p className="small-text">
                          <FiChevronUp size={16} className="redDark"/> mb/s
                          </p> */}
                        </div>
                        <div className="price-icon">
                          <img src={velocidadeDois} alt="preço" />
                        </div>
                      </div>
                      <div className="plan-features">
                        <ul className="list-bordered">
                          <li>Atendimento 24 horas</li>
                          <li>Sem consulta ao SPC/Serasa</li>
                          <li>Wi-fi Grátis</li>
                        </ul>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main2">79 <sup>,90</sup></span>
                        <span className="plan-decimals small-text">/Mês</span>
                        <br/>
                        <small><i>*Taxa de Adesão 2x R$ 80 no cartão</i></small>
                      </div>
                      <div className="plan-button">
                        <a href="#contato" className="btn btn-maincolor">
                          <span>Assine Agora</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="pricing-plan hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor">
                        <h3>80 megas</h3>
                      </div>
                      <div className="plan-desc">
                        <div className="plan-content">
                          <h4 className="color-main2">80</h4>
                          <p className="small-text">
                            <FiChevronDown size={16} className="redLight"/> mb/s
                          </p>
                          {/* <h4 className="color-main">20</h4>
                          <p className="small-text">
                          <FiChevronUp size={16} className="redDark"/> mb/s
                          </p> */}
                        </div>
                        <div className="price-icon">
                          <img src={velocidadeDois} alt="preço" />
                        </div>
                      </div>
                      <div className="plan-features">
                        <ul className="list-bordered">
                          <li>Atendimento 24 horas</li>
                          <li>Sem consulta ao SPC/Serasa</li>
                          <li>Wi-fi Grátis</li>
                        </ul>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main2">89 <sup>,90</sup></span>
                        <span className="plan-decimals small-text">/Mês</span>
                        <br/>
                        <small><i>*Taxa de Adesão 2x R$ 80 no cartão</i></small>
                      </div>
                      <div className="plan-button">
                        <a href="#contato" className="btn btn-maincolor">
                          <span>Assine Agora</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="pricing-plan hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor">
                        <h3>100 megas</h3>
                      </div>
                      <div className="plan-desc">
                        <div className="plan-content">
                          <h4 className="color-main2">100</h4>
                          <p className="small-text">
                            <FiChevronDown size={16} className="redLight"/> mb/s
                          </p>
                          {/* <h4 className="color-main">30</h4>
                          <p className="small-text">
                          <FiChevronUp size={16} className="redDark"/> mb/s
                          </p> */}
                        </div>
                        <div className="price-icon">
                          <img src={velocidadeTres} alt="preço" />
                        </div>
                      </div>
                      <div className="plan-features">
                        <ul className="list-bordered">
                          <li>Atendimento 24 horas</li>
                          <li>Sem consulta ao SPC/Serasa</li>
                          <li>Wi-fi Grátis</li>
                        </ul>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main2">99 <sup>,90</sup></span>
                        <span className="plan-decimals small-text">/Mês</span>
                        <br/>
                        <small><i>*Taxa de Adesão 2x R$ 80 no cartão</i></small>
                      </div>
                      <div className="plan-button">
                        <a href="#contato" className="btn btn-maincolor">
                          <span>Assine Agora</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="pricing-plan hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor">
                        <h3>150 megas</h3>
                      </div>
                      <div className="plan-desc">
                        <div className="plan-content">
                          <h4 className="color-main2">150</h4>
                          <p className="small-text">
                            <FiChevronDown size={16} className="redLight"/> mb/s
                          </p>
                          {/* <h4 className="color-main">40</h4>
                          <p className="small-text">
                          <FiChevronUp size={16} className="redDark"/> mb/s
                          </p> */}
                        </div>
                        <div className="price-icon">
                          <img src={velocidadeTres} alt="preço" />
                        </div>
                      </div>
                      <div className="plan-features">
                        <ul className="list-bordered">
                          <li>Atendimento 24 horas</li>
                          <li>Sem consulta ao SPC/Serasa</li>
                          <li>Wi-fi Grátis</li>
                        </ul>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main2">129 <sup>,90</sup></span>
                        <span className="plan-decimals small-text">/Mês</span>
                        <br/>
                        <small><i>*Taxa de Adesão 2x R$ 80 no cartão</i></small>
                      </div>
                      <div className="plan-button">
                        <a href="#contato" className="btn btn-maincolor">
                          <span>Consulte Agora</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12">
                    <div className="pricing-plan hero-bg rounded">
                      <div className="plan-name text-uppercase bg-maincolor">
                        <h3>200 megas</h3>
                      </div>
                      <div className="plan-desc">
                        <div className="plan-content">
                          <h4 className="color-main2">200</h4>
                          <p className="small-text">
                            <FiChevronDown size={16} className="redLight"/> mb/s
                          </p>
                          {/* <h4 className="color-main">50</h4>
                          <p className="small-text">
                          <FiChevronUp size={16} className="redDark"/> mb/s
                          </p> */}
                        </div>
                        <div className="price-icon">
                          <img src={velocidadeTres} alt="preço" />
                        </div>
                      </div>
                      <div className="plan-features">
                        <ul className="list-bordered">
                          <li>Atendimento 24 horas</li>
                          <li>Sem consulta ao SPC/Serasa</li>
                          <li>Wi-fi Grátis</li>
                        </ul>
                      </div>
                      <div className="price-wrap d-flex">
                        <span className="plan-sign small-text">R$</span>
                        <span className="plan-price color-main2">159 <sup>,90</sup></span>
                        <span className="plan-decimals small-text">/Mês</span>
                        <br/>
                        <small><i>*Taxa de Adesão 2x R$ 80 no cartão</i></small>
                      </div>
                      <div className="plan-button">
                        <a href="#contato" className="btn btn-maincolor">
                          <span>Consulte Agora</span>
                        </a>
                      </div>
                    </div>
                  </div>
              </Slider>
                </div>
              </div>
            </section>
            {/* FIM PLANOS */}
           <section className="s-pb-80 s-pb-lg-120 s-pb-xl-160 devices-section overflow-visible hero-fibra" id="features">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-8 offset-lg-2 text-center">
                    <img src={televisao} className="televisao" alt="SmartTv com internet fibra" />
                    <div className="divider-50" />
                    <h1 className="text-center">O melhor<br/>
                    da Fibra Óptica<br/>
                    na sua Casa!</h1>
                    <p className="text-center big">
                    Assista séries, vídeos, jogue online, home-office.
                      <br className="d-none d-md-block"/> Sua família muito mais conectada!
                    </p>
                    <div className="divider-50 d-none d-md-block" />
                    <a href="#contato" className="btn btn-maincolor">
                      <span>Assine Agora</span>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            {/* Sobre Nós */}
            <section className="sobre" id="sobre">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 offset-lg-6">
                    <h2>Quem Somos</h2>
                    <p>Somos uma empresa com experiência há mais de 10 anos, oferecendo o melhor serviço, com uma equipe altamente capacitada e especializada na instalação, manutenção e com atendimento 24 horas.</p>
                  </div>
                </div>
              </div>
            </section>
            {/* Perguntas Frequentes */}
            <section className="s-pb-70 s-pt-80 s-pt-lg-90 s-pt-xl-90 s-pb-xl-70 faq-page" id="faq">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 p-0">
                  <Accordion defaultActiveKey="0" className="tab-faq">
                    <div className="cards">
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        {/* <FiPlus size={16} className="redLight"/> */}
                         O que é fibra óptica? 
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>A fibra ótica é uma estrutura de vidro cilíndrica, transparente, flexível e com dimensões microscópicas. É uma forma de transmissão que permite o tráfego de dados com velocidades próximas à velocidade da luz. O sinal da fibra ótica é transmitido por meio de reflexões de raios laser ao longo de todo o cabo.</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        Qual é a cobertura ? Atende onde moro ?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>Consulte com a gente se tem cobertura para o seu bairro. <a href="https://api.whatsapp.com/send?phone=551195596-3327&&text=Olá vim através do site www.elitetelecomsp.com.br, gostaria de saber mais sobre os serviços." className="link-contato">Entre em contato.</a></Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        Como aumentar a velocidade da minha internet?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>Para saber mais, <a href="https://api.whatsapp.com/send?phone=551195596-3327&&text=Olá vim através do site www.elitetelecomsp.com.br, gostaria de saber mais sobre os serviços." className="link-contato">consulte conosco</a> outras velocidades para seu endereço ou região.</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        Como contratar um plano de fibra Óptica?
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body> <a href="#planos" className="link-contato">Escolha um plano</a> que atenda as suas necessidades e entre em contato conosco através do <a href="#contato" className="link-contato">formulário</a> de contato ou pelo <a href="https://api.whatsapp.com/send?phone=551195596-3327&&text=Olá vim através do site www.elitetelecomsp.com.br, gostaria de saber mais sobre os serviços." className="link-contato">whatsapp</a>, será um prazer em atender e para esclarecer quaisquer dúvidas.</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    </div>
                  </Accordion>
                  </div>
                </div>
              </div>
            </section>
          </div>          
        );
    }
}

export default Home;