import React from 'react';
import './global.scss';


import Header from './Header';
import Banner from './Banner';
import Home from './Home';
import Contato from './Contato';
import Footer from './Footer';

class App extends React.Component{
    render() {
        return (
            <div>
            <Header/>
            <Banner/>
            <Home/>
            <Contato/>
            <Footer/>
            </div>
        );
    }
}

export default App;