import React from 'react';
import '././style.scss';

class Footer extends React.Component{
  render() {
    return (
      <div>
        <section className="mapa"></section>
        <footer className="rodape">
          <div className="container">
            <div className="row">
              <div className="col-md-12 py-3">
                <h6>© Copyright 2020 - Elite telecom - todos os direitos reservados</h6>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer;