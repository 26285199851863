import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavLink from 'react-bootstrap/NavLink';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';
import Nav from 'react-bootstrap/Nav';
import NavbarToggle from 'react-bootstrap/NavbarToggle';
import '././style.scss';
import {FiChevronRight } from 'react-icons/fi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { NavbarBrand } from 'react-bootstrap';

const logo = require('./../assets/logo-elite.png');

class Header extends React.Component{
  render() {
    return (
      <div className="main-container">
        <div className="main-content">
          <div className="page_topline">
            <div className="container-fluid p-0">
              <div className="row align-items-center">
                <div className="col-md-6 text-md-left text-center">
                <ul className="top-includes">
                  <li>
                    <span className="icon-inline">
                      <span className="small-text">
                      <FontAwesomeIcon icon={faWhatsapp} className="whats icon-topo"/>
                        <a href="https://api.whatsapp.com/send?phone=5511955963327&&text=Olá vim através do site www.elitetelecomsp.com.br, gostaria de saber mais sobre os serviços." target="_blank"rel="noopener noreferrer">11 95596-3327</a>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 text-center text-md-right d-none d-md-block">
                <ul className="top-includes">
                  <li>
                    <span className="icon-inline">
                      <span className="small-text">
                        <a href="#contato">
                          assine agora <FiChevronRight size={16} className="redLight"/>
                        </a>
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        </div>
        <header className="main-header">
          <div className="page_header justify-nav-end">
            <div className="container-fluid">
              <div className="row align-items-center justify-content-between">
              <Navbar collapseOnSelect expand="xl" className="p-0">

              <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                  <NavbarBrand href="/" className="logo">
                      <span className="py-2">
                        <img src={logo} alt="logo"/>
                      </span>
                  </NavbarBrand>
                </div>

              <div className="col-6 col-sm-6 col-md-6 col-lg-6 d-xl-none d-flex justify-content-end">
                <NavbarToggle aria-controls="responsive-navbar-nav"/>
              </div>
                <div className="col-12 col-sm-12 col-md-9 col-lg-9">
                  <NavbarCollapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                    <ul className="menu">
                          <li><NavLink href="/">Home</NavLink></li>
                          <li><NavLink href="#planos">Planos</NavLink></li>
                          <li><NavLink href="#sobre">Quem Somos</NavLink></li>
                          <li><NavLink href="#faq">Perguntas Frequentes</NavLink></li>
                          <li><NavLink href="#contato">Contato</NavLink></li>
                        </ul>
                    </Nav>
                  </NavbarCollapse>
                </div>
              </Navbar>
                </div>
              </div>
            </div>
        </header>
      </div>
    )
  }
}

export default Header;