import React from 'react';
import './style.scss';

class Banner extends React.Component{
  render() {
    return (
      <div className="banner-home">
        <div className="container-fluid">
          <div className="row justify-content-end">
            <div className="col-12 col-sm-8 colamd-6 col-lg-5">
              <div className="row">
               <h1>O melhor da fibra <br className="d-none d-lg-block"/>
                óptica na sua casa!</h1>
              </div>
              <div className="row">
                <div className="col-md-6 p-0"><p>Planos à partir<br/>de  R$ 49,90. </p></div>
                <div className="col-md-6 p-0"><div class="plan-button"><a href="#contato" class="btn btn-maincolor"><span>Assine Agora</span></a></div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Banner;